import { gql } from '@apollo/client';
import {
  FundBasicField,
  InvoiceLoanManagerBasicField,
  InvoiceLoanRosterBasic,
} from './invoice-loan/fragments';
import { UserIdentification } from './user/fragments';
import {
  CompanyDocumentsFields,
  CreditLineFields,
  MasterEntityBasicField,
  BaseContactsField,
} from './customers/fragment';
import {
  AssignmentFields,
  InvoiceDocumentsFields,
  TraceFields,
} from './invoices/fragments';
import { MoneyFields } from './moneyFields';
import { FileFields } from './fileField';

export const CountryFields = gql`
  fragment CountryFields on CountryType {
    id
    name
  }
`;

export const MasterEntityFields = gql`
  fragment MasterEntityFields on MasterEntityType {
    id
    rut
    name
    displayNationalIdentifier
    country {
      ...CountryFields
    }
  }
  ${CountryFields}
`;

export const CredentialFields = gql`
  fragment CredentialFields on CredentialType {
    id
    updatedAt
    username
    secondUsername
    certificate {
      ...FileFields
    }
    authenticationType {
      id
      name
    }
    credentialProvider {
      id
      name
      integrated
      iconUrl
      requiresEmail
      credentialProviderType {
        id
        name
      }
    }
  }
  ${FileFields}
`;

const commonConnectionFields = `
  pageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
  totalCount
  totalPages
`;

export const TypeConnectionFields = gql`
  fragment TypeConnectionFields on InvoiceTypeConnection {
    ${commonConnectionFields}
  }
`;

export const MasterEntityTypeConnectionFields = gql`
  fragment MasterEntityTypeConnectionFields on MasterEntityTypeConnection {
    ${commonConnectionFields}
  }
`;

export const InvoiceFields = gql`
  fragment InvoiceFields on InvoiceType {
    id
    company {
      id
      rut
      name
    }
    folio
    confirming
    nameReceiver
    rutReceiver
    dateIssued
    dateOperation
    dateExpiration
    dateToPay
    amountWithIva {
      ...MoneyFields
    }
    status
    loanedStatus
    siiStatus
    loanedTo
    creditNoteStatus
    preofferSelectable @client
    preoffer {
      id
      monthlyRate
      defaultRate
      retentionRate
      preofferevaluationrequest {
        status
      }
    }
    offer {
      id
    }
    operation {
      id
      commission
    }
  }
  ${MoneyFields}
`;
export const InvoiceTransferFields = gql`
  fragment InvoiceTransferFields on InvoiceType {
    id
    folio
    dateIssued
    dateExpiration
    receptionDate
    hasExecutiveMerit @client
    daysToExecutiveMerit @client
    dateToPay
    status
    loanedTo
    loanedToRut
    amountWithIva {
      ...MoneyFields
    }
    loanedStatus
    creditNoteStatus
    siiStatus
    unreadMessages
    paymentDate
    surplusDebt {
      ...MoneyFields
    }
    isRatificated
    assignmentSet {
      ...AssignmentFields
    }
    preofferSelectable @client
    invoiceStatus @client
    amounts @client
    paymentMethod @client
    lightningPaymentAuthorized @client
    lastOperativeRequestManager {
      id
      status
    }
    invoiceloanmanager {
      ...InvoiceLoanManagerBasicField
      invoiceLoanRoster {
        ...InvoiceLoanRosterBasic
        fund {
          ...FundBasicField
          masterEntity {
            id
            rut
            name
            displayNationalIdentifier
          }
        }
      }
    }
    isFunding @client
    dteType {
      id
      code
      name
    }
    company {
      id
      rut
      name
      hasInvoiceProviderCredentials @client
      validCredentials {
        ...CredentialFields
      }
      executiveAssigned {
        ...UserIdentification
      }
      masterEntity {
        id
        rut
        name
        displayNationalIdentifier
        riskBlacklist {
          id
        }
      }
      currentCommissionRules {
        id
        threshold
        variant
        value
      }
      documents {
        ...CompanyDocumentsFields
      }
      companyevaluationrestrictionsSet {
        id
        status
        restriction
        description
        resolutionExplanation
      }
    }
    receiver {
      id
      rut
      name
      company {
        id
        rut
        invoicingInstructions {
          id
          globalAppId
        }
        hasInvoiceProviderCredentials @client
        validCredentials {
          ...CredentialFields
        }
      }
      riskBlacklist {
        id
      }
    }
    operation {
      id
    }
    uniqueDocumentFinanceState {
      id
      estimatedPaymentDate
      paymentDate
      comments
      isAutomatic
      eventTime: date
      status {
        id
        code
        status
      }
      rejectionCode {
        id
        code
        reason
      }
      bank {
        id
        name
      }
    }
    providerTrace @client {
      id
      estimatedPaymentDate
      paymentDate
      comments
      isAutomatic
      eventTime: date
      status {
        id
        code
        status
      }
      bank {
        id
        name
      }
    }
    providerRejectionReasons @client {
      id
      comments
      isAutomatic
      eventTime: date
      status {
        id
        code
        status
      }
      rejectionCode {
        id
        code
        reason
      }
    }
  }
  ${CredentialFields}
  ${MoneyFields}
  ${CompanyDocumentsFields}
  ${InvoiceLoanManagerBasicField}
  ${InvoiceLoanRosterBasic}
  ${FundBasicField}
  ${AssignmentFields}
  ${UserIdentification}
`;

export const InvoiceReplacementFields = gql`
  fragment InvoiceReplacementFields on InvoiceType {
    backwardsReplacementInvoices {
      id
      replacedInvoice {
        id
        company {
          id
          rut
          name
        }
        folio
      }
      replacementAmount {
        ...MoneyFields
      }
    }
    forwardReplacementInvoices {
      id
      replacingInvoice {
        id
        company {
          id
          rut
          name
        }
        folio
      }
      replacementAmount {
        ...MoneyFields
      }
    }
  }
  ${MoneyFields}
`;

export const InvoiceReintegrationFields = gql`
  fragment InvoiceReintegrationFields on InvoiceType {
    owedReintegrationsInvoices {
      id
      payingInvoice {
        id
        company {
          id
          rut
          name
        }
        folio
      }
      reintegrationAmount {
        ...MoneyFields
      }
    }
    payingReintegrationsInvoices {
      id
      owedInvoice {
        id
        company {
          id
          rut
          name
        }
        folio
      }
      reintegrationAmount {
        ...MoneyFields
      }
    }
  }
  ${MoneyFields}
`;

export const OrderingAssignedFields = gql`
  fragment OrderingAssignedFields on InvoiceType {
    orderingAssigned {
      id
      amount {
        ...MoneyFields
      }
    }
  }
  ${MoneyFields}
`;

export const InvoiceComplianceFields = gql`
  fragment InvoiceComplianceFields on InvoiceType {
    id
    company {
      legalRepresentative {
        id
        relatedPerson {
          id
          name
          compliance {
            id
            requirementsClean
            information
          }
        }
      }
      masterEntity {
        id
        rut
        name
        displayNationalIdentifier
        riskBlacklist {
          id
        }
        compliance {
          id
          readyForTransfer
          status
          requirementsClean
          information
          regcheqId
        }
      }
    }
  }
`;

export const InvoiceBasicFields = gql`
  fragment InvoiceBasicFields on InvoiceType {
    id
    folio
    dateIssued
    dateExpiration
    receptionDate
    hasExecutiveMerit @client
    daysToExecutiveMerit @client
    dateToPay
    status
    loanedTo
    loanedToRut
    amountWithIva {
      ...MoneyFields
    }
    loanedStatus
    creditNoteStatus
    siiStatus
    unreadMessages
    paymentDate
    surplusDebt {
      ...MoneyFields
    }
    isRatificated
    historystatusSet {
      id
      createdAt
      status
    }
    preofferSelectable @client
    invoiceStatus @client
    amounts @client
    paymentMethod @client
    lightningPaymentAuthorized @client
    invoiceloanmanager {
      ...InvoiceLoanManagerBasicField
      invoiceLoanRoster {
        ...InvoiceLoanRosterBasic
        fund {
          ...FundBasicField
          masterEntity {
            id
            name
            rut
            displayNationalIdentifier
          }
        }
      }
    }
    isFunding @client
    relatedConfirming {
      id
      actualAmortizationSchedule {
        id
        installments {
          id
          amortizationToPay {
            ...MoneyFields
          }
          interestAmountToPay {
            ...MoneyFields
          }
        }
      }
    }
    binnacleSet {
      message
      username
      id
      mail
      username
    }
    dteType {
      id
      code
      name
    }
    invoicedetail {
      id
      purchaseOrderFolio
    }
    intermediateReferencesFrom {
      toInvoice {
        id
        folio
        amountWithIva {
          ...MoneyFields
        }
        dteType {
          id
          code
          name
        }
      }
      amount {
        ...MoneyFields
      }
    }
    intermediateReferencesTo {
      fromInvoice {
        id
        folio
        amountWithIva {
          ...MoneyFields
        }
        dteType {
          id
          code
          name
        }
      }
      amount {
        ...MoneyFields
      }
    }
    creditNotes @client {
      id
      folio
      amountWithIva {
        ...MoneyFields
      }
    }
    debitNotes @client {
      id
      folio
      amountWithIva {
        ...MoneyFields
      }
    }
    cessions {
      id
      cessionDate
      transferor {
        id
        rut
        name
      }
      assignee {
        id
        rut
        name
      }
    }
    traces {
      ...TraceFields
    }
    company {
      id
      rut
      name
      hasInvoiceProviderCredentials @client
      hasLendingAcceptedterms @client
      currentUserAcceptedTerms {
        id
        product
      }
      hasDigitalCertificate
      hasFrameworkContract
      validCredentials {
        ...CredentialFields
      }
      executiveAssigned {
        ...UserIdentification
      }
      masterEntity {
        ...MasterEntityBasicField
        riskBlacklist {
          id
        }
      }
      currentCommissionRules {
        id
        threshold
        variant
        value
      }
      documents {
        ...CompanyDocumentsFields
      }
      companyevaluationrestrictionsSet {
        id
        status
        restriction
        description
        resolutionExplanation
      }
      pendingReintegrations {
        count
        totalAmount {
          ...MoneyFields
        }
      }
    }
    receiver {
      ...MasterEntityBasicField
      riskBlacklist {
        id
      }
      company {
        id
        rut
        invoicingInstructions {
          id
          globalAppId
        }
        hasInvoiceProviderCredentials @client
        validCredentials {
          ...CredentialFields
        }
      }
    }
    operation {
      id
      requestingPlatform {
        id
        code
      }
    }
    isForOrdering
    uniqueDocumentFinanceState {
      id
      estimatedPaymentDate
      paymentDate
      comments
      isAutomatic
      eventTime: date
      status {
        id
        code
        status
      }
      rejectionCode {
        id
        code
        reason
      }
      bank {
        id
        name
      }
    }
    providerTrace @client {
      id
      estimatedPaymentDate
      paymentDate
      comments
      isAutomatic
      eventTime: date
      status {
        id
        code
        status
      }
      bank {
        id
        name
      }
    }
    providerRejectionReasons @client {
      id
      comments
      isAutomatic
      eventTime: date
      status {
        id
        code
        status
      }
      rejectionCode {
        id
        code
        reason
      }
    }
    invoiceDebt {
      id
      days
      debt {
        ...MoneyFields
      }
    }
    mailInvoices {
      id
      mailParameter {
        mail {
          id
          emailName
        }
      }
      updatedAt
      sendGridData {
        id
        toEmail
        events {
          id
          createdAt
          status
        }
      }
    }
    invoiceMoneyTransfer {
      id
      status
    }
  }
  ${MasterEntityBasicField}
  ${TraceFields}
  ${CredentialFields}
  ${MoneyFields}
  ${CompanyDocumentsFields}
  ${InvoiceLoanManagerBasicField}
  ${InvoiceLoanRosterBasic}
  ${FundBasicField}
  ${UserIdentification}
`;

export const InvoiceFilesFields = gql`
  fragment InvoiceFilesFields on InvoiceType {
    assignmentSet {
      ...AssignmentFields
    }
    documents {
      ...InvoiceDocumentsFields
    }
  }
  ${InvoiceDocumentsFields}
  ${AssignmentFields}
`;

export const InvoiceRatesFields = gql`
  fragment InvoiceRatesFields on InvoiceType {
    preoffer {
      id
      monthlyRate
      defaultRate
      retentionRate
      preofferevaluationrequest {
        id
        status
      }
    }
    offer {
      id
      monthlyRate
      defaultRate
      retentionRate
      transferCondition
      fundingDaysAfterExpiration
    }
  }
`;

export const InvoicePreofferRatesFields = gql`
  fragment InvoicePreofferRatesFields on InvoiceType {
    preoffer {
      id
      monthlyRate
      defaultRate
      retentionRate
      preofferevaluationrequest {
        id
        status
      }
    }
  }
`;

export const InvoiceOfferRatesFields = gql`
  fragment InvoiceOfferRatesFields on InvoiceType {
    offer {
      id
      monthlyRate
      defaultRate
      retentionRate
      transferCondition
      fundingDaysAfterExpiration
    }
  }
`;

export const InvoiceCollectionFields = gql`
  fragment InvoiceCollectionFields on InvoiceType {
    collectionManager {
      id
      status
      forReintegration
      collectionPriority {
        id
        value
      }
      collector {
        ...UserIdentification
      }
      actions {
        id
        comment
        actionType
        contacts {
          ...BaseContactsField
        }
        author {
          ...UserIdentification
        }
        createdAt
      }
      currentDataForCollection {
        id
        dateToPay
        paymentMethod
      }
    }
  }
  ${UserIdentification}
  ${BaseContactsField}
`;

export const RatificationManagerFields = gql`
  fragment RatificationManagerFields on RatificationManagerType {
    id
    createdAt
    assignedPriorityDatetime
    ratificator {
      ...UserIdentification
    }
    actions {
      id
      actionType
      comment
      createdAt
      channel
      responded
      contacts {
        ...BaseContactsField
      }
      author {
        ...UserIdentification
      }
    }
  }
  ${UserIdentification}
  ${BaseContactsField}
`;

export const InvoiceRatificationFields = gql`
  fragment InvoiceRatificationFields on InvoiceType {
    lastHistoryStatus {
      id
      status
      createdAt
    }
    ratificationmanager {
      ...RatificationManagerFields
    }
  }
  ${RatificationManagerFields}
`;

export const InvoicePaymentDiscountFields = gql`
  fragment InvoicePaymentDiscountFields on InvoiceType {
    paymentDiscounts {
      id
      paymentAmountAfterNegativeSurplus {
        ...MoneyFields
      }
      negativeSurplusAsociated {
        ...MoneyFields
      }
      commissionAsociated {
        ...MoneyFields
      }
      interestAsociated {
        ...MoneyFields
      }
      reintegrationAmountAssociated {
        ...MoneyFields
      }
      replacementAmountAssociated {
        ...MoneyFields
      }
      orderingAmountAssociated {
        ...MoneyFields
      }
      retentionAssociated {
        ...MoneyFields
      }
      amountAfterRetention {
        ...MoneyFields
      }
    }
  }
  ${MoneyFields}
`;

export const InvoiceMoneyTransferFields = gql`
  fragment InvoiceMoneyTransferFields on InvoiceType {
    invoiceMoneyTransfer {
      id
      createdAt
    }
  }
`;

export const InvoiceMessageFields = gql`
  fragment InvoiceMessageFields on InvoiceMessageType {
    id
    user {
      id
      firstName
      lastName
      completeName @client
    }
    message
    messageType
    createdAt
    updatedAt
    invoice {
      id
    }
    trace {
      id
      eventTime
    }
  }
`;

export const PurchaseOrderFragment = gql`
  fragment PurchaseOrderFragment on OrderingPurchaseOrderType {
    id
    orderStatus
    publicationDate
    totalAmount {
      ...MoneyFields
    }
    currency
    orderNumber
    status
    simulationSelectable @client
    nonAvailableReason @client
    company {
      id
      rut
      masterEntity {
        ...MasterEntityBasicField
        riskBlacklist {
          id
        }
      }
      executiveAssigned {
        ...UserIdentification
      }
    }
    orderingDebt {
      id
      days
      debt {
        ...MoneyFields
      }
    }
    collectionProrogation {
      id
      createdAt
      prorogationDate
    }
    collectionManager @include(if: $inCollection) {
      id
      collector {
        ...UserIdentification
      }
      collectionPriority {
        id
        value
      }
      currentDataForCollection {
        id
        dateToPay
        paymentMethod
      }
      actions {
        id
        comment
        createdAt
        actionType
        contacts {
          ...BaseContactsField
        }
        author {
          ...UserIdentification
        }
      }
    }
    orderingsimulation @include(if: $getRates) {
      id
      orderingMonthlyRate
      orderingDefaultRate
      orderingRetentionRate
      orderingAmount {
        ...MoneyFields
      }
      otherDiscounts {
        ...MoneyFields
      }
      orderingRetention {
        ...MoneyFields
      }
      orderingFinancedAmount {
        ...MoneyFields
      }
      orderingCommission {
        ...MoneyFields
      }
      orderingInterest {
        ...MoneyFields
      }
      orderingPaymentAmount {
        ...MoneyFields
      }
      factoringMonthlyRate
      factoringDefaultRate
      factoringRetentionRate
      factoringInterest {
        ...MoneyFields
      }
      factoringCommission {
        ...MoneyFields
      }
      factoringRetention {
        ...MoneyFields
      }
      factoringPaymentAmount {
        ...MoneyFields
      }
      invoiceIssuedDate
      invoiceDateToPay
    }
    orderingoffer @include(if: $getRates) {
      id
      createdAt
      updatedAt
      orderingMonthlyRate
      orderingDefaultRate
      orderingRetentionRate
      orderingAmount {
        ...MoneyFields
      }
      otherDiscounts {
        ...MoneyFields
      }
      orderingRetention {
        ...MoneyFields
      }
      orderingFinancedAmount {
        ...MoneyFields
      }
      orderingCommission {
        ...MoneyFields
      }
      orderingInterest {
        ...MoneyFields
      }
      orderingPaymentAmount {
        ...MoneyFields
      }
      factoringMonthlyRate
      factoringDefaultRate
      factoringRetentionRate
      factoringInterest {
        ...MoneyFields
      }
      factoringCommission {
        ...MoneyFields
      }
      factoringRetention {
        ...MoneyFields
      }
      factoringPaymentAmount {
        ...MoneyFields
      }
      invoiceIssuedDate
      invoiceDateToPay
      operationDate
      invoiceFundingDaysAfterExpiration
    }
    invoiceAssignationRecords {
      amount {
        ...MoneyFields
      }
      dateToPay
      invoice {
        id
        folio
        dateIssued
        amountWithIva {
          ...MoneyFields
        }
        dateToPay
        receiver {
          id
          name
          rut
          displayNationalIdentifier
        }
        offer {
          id
          monthlyRate
          defaultRate
        }
        preoffer {
          id
          monthlyRate
          defaultRate
        }
      }
      invoiceAssignationOperation {
        id
        status {
          id
          name
        }
      }
    }
    orderingsenttocollectionregisterSet {
      collectionDate
      paymentDate
    }
    purchaser {
      ...MasterEntityBasicField
      riskBlacklist {
        id
      }
    }
    invoiceMoneyTransfer {
      id
      createdAt
      status
    }
  }
  ${MasterEntityBasicField}
  ${MoneyFields}
  ${UserIdentification}
  ${FileFields}
  ${BaseContactsField}
`;

export const ShoppingFields = gql`
  fragment ShoppingFields on ShoppingCartSummaryType {
    invoiceCount
    totalAmount {
      ...MoneyFields
    }
    priceDifference {
      ...MoneyFields
    }
    commission {
      ...MoneyFields
    }
    totalCosts {
      ...MoneyFields
    }
    retainedAmount {
      ...MoneyFields
    }
    orderingDebt {
      ...MoneyFields
    }
    negativeSurplus {
      ...MoneyFields
    }
    rate
    hasPurchaseOrdersNearToDebt
    depositAmount {
      ...MoneyFields
    }
  }
  ${MoneyFields}
`;

export const CompanyFields = gql`
  fragment CompanyFields on CompanyType {
    id
    rut
    name
    phoneNumber
    hasBankAccount
    isAcepta
    documents {
      ...CompanyDocumentsFields
    }
    
    masterEntity {
      id
      rut
      name
      graphqlId @client
      displayNationalIdentifier
      debtAmortizationSchedule {
      count
      totalAmount {
        ...MoneyFields
      }
    } 
      country {
        id
        name
      }
      creditlines {
        ...CreditLineFields
      }
    }
    bankAccounts {
      id
      bankName
      accountType
      accountNumber
      accountEmail
      accountName
      active
      updatedAt
      currency {
        id
        isoCode
      }
    }
    currentCommissionRules {
      id
      threshold
      variant
      value
    }
    integrationMethod
    hasSiiCredentials @client
    hasInvoiceProviderCredentials @client
    possibleInvoiceProvider {
      id
      name
      integrated
      iconUrl
      requiresEmail
      credentialProviderType {
        id
        name
      }
    }
    hasSupplierPortalCredentials @client
    hasLendingAcceptedterms @client
    hasCollectionAcceptedTerms @client
    currentUserAcceptedTerms {
      id
      product
      requestingPlatform {
        id
        code
      }
    }
    hasDigitalCertificate
    hasCertificateCredentials
    pendingReintegrations {
      count
      totalAmount {
        ...MoneyFields
      }
    }
    currentDigitalCertificate {
      id
      certificate {
        ...FileFields
      }
      updatedAt
      expireDatetime
    }
    hasOrderingDebt
    hasFrameworkContract
    hasMissingCredentials
    currentSurplusBalanceValue {
      ...MoneyFields
    }
    negativeSurplusBalance @client
    showReferralCampaign
    isSenegocia
    isOrdering
    isManagermas
    groupModules {
      id
      code
      permissions {
        id
        code
        navTitle
      }
    }
    currentCompanyConfirmingCreditLine {
      id
      limit
      monthlyRate
    }
    executiveAssigned {
      id
      firstName
      lastName
      email
      phoneNumber
    }
    navBarTitles @client
    validCredentials {
      ...CredentialFields
    }
    siiCredentials @client {
      ...CredentialFields
    }
    invoiceProviderCredentials @client {
      ...CredentialFields
    }
    supplierPortalCredentials @client {
      ...CredentialFields
    }
  }
  ${CreditLineFields}
  ${CompanyDocumentsFields}
  ${CredentialFields}
  ${MoneyFields}
  ${FileFields}
`;

export const BasicUserFields = gql`
  fragment BasicUserFields on UserType {
    id
    firstName
    lastName
    completeName @client
    picture {
      name
      url
    }
  }
`;

export const NPSFields = gql`
  fragment NPSFields on NPSType {
    id
    npsType {
      id
      name
      questions
      title
      reasons {
        category
        categoryToEs
      }
    }
  }
`;

export const UserFields = gql`
  fragment UserFields on UserType {
    id
    rut
    demo
    email
    firstName
    lastName
    isPhoneVerified
    isEmailVerified
    completeName @client
    phoneNumber
    mailProvided
    registerComplete
    defaultCompanyRut
    hasProfileInfoMissing
    isSuperuser
    isStaff
    isExternalSupport
    hasDummyPassword
    requireChangePassword
    hasActivePhoneVerificationCode @client
    phoneverification {
      id
      expirationDate
    }
    userGroups
    registrationSteps {
      step
      origin
      success
    }
    userseentutorialSet {
      id
      tutorialCode
      seen
    }
    activeNpsResponse {
      ...NPSFields
    }
    selectedCompany {
      ...CompanyFields
    }
    graphqlId @client
    completeRut
    enabledSupplierPortals {
      companyFrom {
        id
        rut
        name
        companyfiles {
          id
          profilePictureUrl
        }
      }
    }
    usergamehighscore {
      highScore
    }
    permissions
    userPlanSubscriptions(isActive: true) {
      edges {
        node {
          id
          isActive
          isAdmin
          subscription {
            id
            plan {
              id
            }
          }
        }
      }
    }
    executive {
      id
      isCommercialExecutive
    }
  }
  ${NPSFields}
  ${CompanyFields}
`;

export const BackOfficeUserFields = gql`
  fragment BackOfficeUserFields on UserType {
    id
    rut
    demo
    email
    firstName
    lastName
    isPhoneVerified
    isEmailVerified
    completeName @client
    phoneNumber
    mailProvided
    registerComplete
    defaultCompanyRut
    hasProfileInfoMissing
    isSuperuser
    isStaff
    isExternalSupport
    hasDummyPassword
    requireChangePassword
    hasActivePhoneVerificationCode @client
    phoneverification {
      id
      expirationDate
    }
    userGroups
    registrationSteps {
      step
      origin
      success
    }
    userseentutorialSet {
      id
      tutorialCode
      seen
    }
    activeNpsResponse {
      id
      npsType {
        id
        name
        questions
        title
        reasons {
          category
          categoryToEs
        }
      }
    }
    completeRut
    enabledSupplierPortals {
      companyFrom {
        id
        rut
        name
        companyfiles {
          id
          profilePictureUrl
        }
      }
    }
    permissions
    invoicesInOperationWithRestrictionsCount
    executive {
      id
      isCommercialExecutive
    }
  }
`;

const NotificationTypeConnectionFragment = gql`
  fragment NotificationTypeConnectionFragment on NotificationTypeConnection {
    totalCount
    edges {
      node {
        id
        timestamp
        level
        unread
        actorStr
        verbStr
        targetStr
        redirectionPath
        redirectionSearch
      }
    }
  }
`;

export const sharedInvoiceInputFields = `
    $distinct: Boolean,
    $allIssuedCompany: Boolean,
    $allReceiverCompany: Boolean,
    $availableForFinancing: String,
    $hasActivePreoffer: Boolean,
    $hasValidPreoffer: Boolean,
    $hasActiveOperation: Boolean,
    $hasEvaluatingPreoffer: Boolean,
    $hasRejectedPreoffer: Boolean,
    $company_MasterEntity_Name_Icontains: String,
    $company_MasterEntity_Name_Iexact: String,
    $company_MasterEntity_Name_Istartswith: String,
    $preoffer_Preofferevaluationrequest_Status: String,
    $preoffer_Preofferevaluationrequest_RequestingPlatform_Code: String,
    $preoffer_Preofferevaluationrequest_Status_In: [String],
    $preoffer_Preofferevaluationrequest_Isnull: Boolean,
    $ratificationmanager_Actions_Isnull: Boolean,
    $ratificationmanager_RatificationPriority_Value_In: [Int],
    $collectionManagerRelation_Actions_Isnull: Boolean,
    $companyId: Int,
    $companyId_In: [ID],
    $currency: String,
    $creditNoteStatus: [String],
    $dateIssued: Date,
    $dateIssued_Gt: Date,
    $dateIssued_Gte: Date,
    $dateIssued_Lt: Date,
    $dateIssued_Lte: Date,
    $dateExpiration: Date,
    $dateExpiration_Gt: Date,
    $dateExpiration_Gte: Date,
    $dateExpiration_Lt: Date,
    $dateExpiration_Lte: Date,
    $dateToPay: Date,
    $dateToPay_Gt: Date,
    $dateToPay_Gte: Date,
    $dateToPay_Lt: Date,
    $dateToPay_Lte: Date,
    $dfstateIn: [String],
    $documentfinancestate_PaymentDate_Gte: Date,
    $documentfinancestate_PaymentDate_Lte: Date,
    $dteType_Code_In: [String],
    $dteType_Code: String,
    $dteType_Country_Name: String,
    $dteType_CountryId: Int,
    $first: Int,
    $folio: String,
    $globalFilter: String = "",
    $hasBeenCeded: Boolean,
    $hasNotOffer: Boolean,
    $hasOffer: Boolean,
    $hasPreoffer: Boolean,
    $hasValidOffer: Boolean,
    $id_In: [String],
    $loanedStatus: [String],
    $offset: Int,
    $orderBy: String = "-dateIssued",
    $receiverId: Int,
    $receiver_In: [ID],
    $receiver_Name_Icontains: String,
    $receiver_Name_Iexact: String,
    $receiver_Name_Istartswith: String,
    $receiver_Rut_In: [String],
    $receiver_Rut: String,
    $receptionDate_Gt: DateTime,
    $receptionDate_Gte: DateTime,
    $receptionDate_Lt: DateTime,
    $receptionDate_Lte: DateTime,
    $receptionDate: DateTime,
    $siiStatus: [String],
    $status: [String],
    $relatedConfirming_Status_In: [String],
    $relatedConfirming_InvoiceMoneyTransfer_Status: String,
    $assignmentSet_Status: String,
    $relatedConfirming_Id_Isnull: Boolean
    $inCollection: Int,
    $hasPendingCollection: Boolean
    $historicCollection: Int,
    $availableForOrdering: Boolean = false,
    $availableForTransfer: Boolean = false,
    $availableForReintegration: Boolean = false,
    $availableForReplacement: Boolean = false,
    $recommended: Boolean = false,
    $ratificationStatus: String,
    $validDteType: Boolean,
    $filterByLightningPaymentAuthorized: Boolean,
    $validSiiCredendials: Boolean,
    $collectionDate: Date,
    $hasPendingSignature: Boolean = false,
    $filterByRatificator: Boolean = false,
    $debtorId: String,
    $operation_RequestingPlatform_Code: String,
    $collectionManagerRelation_Status: String,
    $filterByPendingRestrictions: Boolean,
    $filterByRequestingPlatformOrAssigned: String,
    $companyExecutiveAssignedId: ID,    
    $farmingByCommercialType: String,
`;

export const sharedInvoiceInput = `
    distinct: $distinct,
    allIssuedCompany: $allIssuedCompany,
    allReceiverCompany: $allReceiverCompany,
    hasNotOffer: $hasNotOffer,
    hasOffer: $hasOffer,
    hasPreoffer: $hasPreoffer,
    availableForFinancing: $availableForFinancing,
    hasActivePreoffer: $hasActivePreoffer,
    hasValidPreoffer: $hasValidPreoffer,
    hasActiveOperation: $hasActiveOperation,
    hasEvaluatingPreoffer: $hasEvaluatingPreoffer,
    hasRejectedPreoffer: $hasRejectedPreoffer,
    company_MasterEntity_Name_Icontains: $company_MasterEntity_Name_Icontains, 
    company_MasterEntity_Name_Iexact: $company_MasterEntity_Name_Iexact, 
    company_MasterEntity_Name_Istartswith: $company_MasterEntity_Name_Istartswith, 
    companyId: $companyId,
    companyId_In: $companyId_In,
    creditNoteStatus: $creditNoteStatus,
    currency: $currency,
    status: $status,
    dateIssued_Gt: $dateIssued_Gt,
    dateIssued_Gte: $dateIssued_Gte,
    dateIssued_Lt: $dateIssued_Lt,
    dateIssued_Lte: $dateIssued_Lte,
    dateIssued: $dateIssued,
    dateExpiration_Gt: $dateExpiration_Gt,
    dateExpiration_Gte: $dateExpiration_Gte,
    dateExpiration_Lt: $dateExpiration_Lt,
    dateExpiration_Lte: $dateExpiration_Lte,
    dateExpiration: $dateExpiration,
    dateToPay_Gt: $dateToPay_Gt,
    dateToPay_Gte: $dateToPay_Gte,
    dateToPay_Lt: $dateToPay_Lt,
    dateToPay_Lte: $dateToPay_Lte,
    dateToPay: $dateToPay,
    dfstateIn: $dfstateIn,
    documentfinancestate_PaymentDate_Gte: $documentfinancestate_PaymentDate_Gte,
    documentfinancestate_PaymentDate_Lte: $documentfinancestate_PaymentDate_Lte,
    dteType_Code_In: $dteType_Code_In,
    dteType_Code: $dteType_Code,
    dteType_Country_Name: $dteType_Country_Name,
    dteType_CountryId: $dteType_CountryId,
    first: $first,
    folio: $folio,
    globalFilter: $globalFilter,
    hasBeenCeded: $hasBeenCeded,
    hasValidOffer: $hasValidOffer,
    id_In: $id_In,
    loanedStatus: $loanedStatus,
    offset: $offset,
    orderBy: $orderBy,
    receiver_Name_Icontains: $receiver_Name_Icontains, 
    receiver_Name_Iexact: $receiver_Name_Iexact, 
    receiver_Name_Istartswith: $receiver_Name_Istartswith, 
    receiverId: $receiverId,
    receiver_In: $receiver_In,
    receiver_Rut_In: $receiver_Rut_In,
    receiver_Rut: $receiver_Rut,
    receptionDate_Gt: $receptionDate_Gt,
    receptionDate_Gte: $receptionDate_Gte,
    receptionDate_Lt: $receptionDate_Lt,
    receptionDate_Lte: $receptionDate_Lte,
    receptionDate: $receptionDate,
    siiStatus: $siiStatus,
    relatedConfirming_Status_In: $relatedConfirming_Status_In,
    relatedConfirming_InvoiceMoneyTransfer_Status: $relatedConfirming_InvoiceMoneyTransfer_Status,
    assignmentSet_Status: $assignmentSet_Status,
    relatedConfirming_Id_Isnull: $relatedConfirming_Id_Isnull,
    inCollection: $inCollection,
    hasPendingCollection: $hasPendingCollection
    historicCollection: $historicCollection,
    availableForOrdering: $availableForOrdering,
    availableForTransfer: $availableForTransfer,
    availableForReintegration: $availableForReintegration,
    availableForReplacement: $availableForReplacement,
    recommended: $recommended,
    ratificationStatus: $ratificationStatus,
    validDteType: $validDteType,
    filterByLightningPaymentAuthorized: $filterByLightningPaymentAuthorized,
    validSiiCredendials: $validSiiCredendials,
    collectionDate: $collectionDate,
    preoffer_Preofferevaluationrequest_Status: $preoffer_Preofferevaluationrequest_Status,
    preoffer_Preofferevaluationrequest_Status_In: $preoffer_Preofferevaluationrequest_Status_In,
    preoffer_Preofferevaluationrequest_Isnull: $preoffer_Preofferevaluationrequest_Isnull,
    preoffer_Preofferevaluationrequest_RequestingPlatform_Code: $preoffer_Preofferevaluationrequest_RequestingPlatform_Code,
    ratificationmanager_Actions_Isnull: $ratificationmanager_Actions_Isnull,
    ratificationmanager_RatificationPriority_Value_In: $ratificationmanager_RatificationPriority_Value_In,
    collectionManagerRelation_Actions_Isnull: $collectionManagerRelation_Actions_Isnull,
    hasPendingSignature: $hasPendingSignature
    filterByRatificator: $filterByRatificator
    debtorId: $debtorId
    operation_RequestingPlatform_Code: $operation_RequestingPlatform_Code
    collectionManagerRelation_Status: $collectionManagerRelation_Status
    filterByPendingRestrictions: $filterByPendingRestrictions,
    filterByRequestingPlatformOrAssigned: $filterByRequestingPlatformOrAssigned,
    farmingByCommercialType: $farmingByCommercialType,
    company_ExecutiveAssignedId: $companyExecutiveAssignedId,
`;

export const NotificationMenuFields = gql`
  fragment NotificationMenuFields on UserType {
    notificationsUnreadCount: notifications(first: 7, unread: true) {
      ...NotificationTypeConnectionFragment
    }
    all: notifications(first: 7) {
      ...NotificationTypeConnectionFragment
    }
    mentions: notifications(first: 7, level: "mention") {
      ...NotificationTypeConnectionFragment
    }
  }
  ${NotificationTypeConnectionFragment}
`;

export const BankAccountFields = gql`
  fragment BankAccountFields on BankAccountType {
    id
    active
    bankName
    accountEmail
    accountNumber
    currency {
      id
      isoCode
    }
  }
`;

export const ConfirmingPayrollFields = gql`
  fragment ConfirmingPayrollFields on ConfirmingPayrollType {
    id
    createdAt
    status {
      id
      status
    }
    totalAmount @client
    totalCount @client
    supplierCount @client
    statusInfo @client
    companyName @client
    companyBlacklist @client
    companyRut @client
    areAllOperationsEqual @client
    operations {
      id
      contactEmail
      expirationDate
      monthlyRate
      supplierName @client
      supplierRut @client
      totalAmount @client
      totalCount @client
      statusInfo @client
      bankAccount {
        ...BankAccountFields
      }
      confirmingSet {
        id
        folio
        status
        dateIssued
        dateExpiration
        receiver {
          id
          masterEntity {
            ...MasterEntityFields
            riskBlacklist {
              id
            }
          }
        }
        company {
          id
          masterEntity {
            ...MasterEntityFields
          }
          bankAccounts {
            ...BankAccountFields
          }
        }
        invoice {
          id
          mailInvoices {
            id
            createdAt
          }
        }
        amountWithIva {
          ...MoneyFields
        }
      }
    }
  }
  ${MoneyFields}
  ${BankAccountFields}
  ${MasterEntityFields}
`;

export const MailInvoicesFields = gql`
  fragment MailInvoicesFields on MailLogType {
    id
    mailParameter {
      id
      mail {
        id
        emailName
      }
    }
    updatedAt
    sendGridData {
      id
      toEmail
      events {
        id
        createdAt
        status
      }
    }
  }
`;
